<template>
  <div class="bg-white border rounded-md shadow-sm flex w-96 mt-2">
    <div class="flex items-center px-3 border-r">
      <input
        v-model="checked"
        type="checkbox"
        class="
          w-4
          h-4
          rounded
          cursor-pointer
          border-gray-300
          text-primary-600
          focus:ring-primary-600
        "
      />
    </div>
    <div class="flex-1 py-2 px-3"> {{ label }} </div>
  </div>
</template>
<script>
export default {
  emits: ['change'],
  props: {
    label: String,
    value: Boolean
  },
  data() {
    return {
      checked: this.value
    };
  },
  watch: {
    checked() {
      this.$emit('change', this.checked);
    }
  }
};
</script>
