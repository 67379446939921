<template>
  <app-card class="place-self-center py-8 px-4 w-full max-w-sm">
    <form class="space-y-6" v-on:submit.prevent="submit">
      <app-input :disabled="loading" v-model:options="email" />
      <app-input :disabled="loading" v-model:options="password" />
      <app-button :loading="loading" wide type="submit"> Continuar </app-button>
    </form>
  </app-card>
</template>
<script>
export default {
  data() {
    return {
      email: {
        value: '',
        label: 'E-mail',
        autocomplete: 'username',
        inputmode: 'email',
        rules: ['required', 'email']
      },
      password: {
        value: '',
        label: 'Senha',
        type: 'password',
        autocomplete: 'current-password',
        rules: ['required']
      },
      loading: false
    };
  },
  watch: {
    '$root.session': {
      immediate: true,
      handler(session, oldValue) {
        if (session) {
          if (oldValue !== undefined) this.$router.push({ name: 'home' });
          else this.$router.replace({ name: 'home' });
        }
      }
    }
  },
  methods: {
    submit() {
      if (this.$root.showErrors(this.email, this.password)) return;
      const body = {
        email: this.email.value,
        password: this.password.value
      };
      this.loading = true;
      this.api('session.create', body)
        .then(res => {
          this.$root.updateSession(res.data);
        })
        .catch(err => {
          const status = (err.response && err.response.status) || 500;
          const messages = {
            404: 'O e-mail inserido não corresponde a nenhuma conta.',
            400: 'A senha inserida está incorreta.',
            500: 'Algo deu errado, tente novamente.'
          };
          this.$root.notifications.push({
            message: messages[status]
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
