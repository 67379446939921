<template>
  <component :is="icon" :class="type === 'solid' ? 'h-5 w-5' : 'h-6 w-6'" />
</template>
<script>
export default {
  components: {
    'solid-eye': require('@heroicons/vue/solid/EyeIcon'),
    'solid-eye-off': require('@heroicons/vue/solid/EyeOffIcon'),
    'solid-exclamation-circle': require('@heroicons/vue/solid/ExclamationCircleIcon'),
    'solid-office-building': require('@heroicons/vue/solid/OfficeBuildingIcon'),
    'solid-clipboard-copy': require('@heroicons/vue/solid/ClipboardCopyIcon'),
    'solid-credit-card': require('@heroicons/vue/solid/CreditCardIcon'),
    'solid-calendar': require('@heroicons/vue/solid/CalendarIcon'),
    'solid-refresh': require('@heroicons/vue/solid/RefreshIcon'),
    'solid-logout': require('@heroicons/vue/solid/LogoutIcon'),
    'solid-qrcode': require('@heroicons/vue/solid/QrcodeIcon'),
    'solid-users': require('@heroicons/vue/solid/UsersIcon'),
    'solid-check': require('@heroicons/vue/solid/CheckIcon'),
    'solid-phone': require('@heroicons/vue/solid/PhoneIcon'),
    'solid-mail': require('@heroicons/vue/solid/MailIcon'),
    'solid-chat': require('@heroicons/vue/solid/ChatIcon'),
    'solid-user': require('@heroicons/vue/solid/UserIcon'),
    'solid-home': require('@heroicons/vue/solid/HomeIcon'),
    'solid-plus': require('@heroicons/vue/solid/PlusIcon'),
    'solid-rss': require('@heroicons/vue/solid/RssIcon'),
    'solid-x': require('@heroicons/vue/solid/XIcon'),
    'outline-x': require('@heroicons/vue/outline/XIcon')
  },
  props: {
    name: String,
    type: {
      type: String,
      default: 'solid'
    }
  },
  computed: {
    icon() {
      return `${this.type}-${this.name}`;
    }
  }
};
</script>
