import { createApp } from 'vue';
import axios from 'axios';
import App from './app';
import router from './router';
import api from './plugins/api';
import components from './plugins/components';
import 'tailwindcss/tailwind.css';

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

createApp(App).use(router).use(api).use(components).mount('#app');
