import { createRouter, createWebHistory } from 'vue-router';
import Page from '../components/page';
import Home from '../views/home';
import LogIn from '../views/log-in';
import Events from '../views/events';
import Accounts from '../views/accounts';
import NotFound from '../views/not-found';

const routes = [
  {
    path: '/',
    component: Page,
    children: [
      {
        path: '',
        name: 'home',
        component: Home
      },
      {
        path: 'events',
        name: 'events',
        component: Events
      },
      {
        path: 'accounts',
        name: 'accounts',
        component: Accounts
      }
    ]
  },
  {
    path: '/log-in',
    name: 'log-in',
    component: LogIn
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
