<template>
  <div class="flex">
    <div class="flex-1 flex flex-col">
      <div class="bg-white border-b hidden-scrollbar">
        <div class="max-w-3xl mx-auto p-4">
          <div class="flex items-center justify-between">
            <div class="text-2xl"> Eventos </div>
            <select
              v-model="filter"
              class="
                w-48
                pl-3
                pr-10
                py-2
                text-sm
                rounded
                border-gray-300
                focus:outline-none
                focus:ring-primary-600
                focus:border-primary-600
              "
            >
              <option value="all"> Todos </option>
              <option value="pending"> Pendentes </option>
            </select>
          </div>
        </div>
      </div>
      <div class="flex-1 relative">
        <div class="absolute inset-0 overflow-y-scroll">
          <div class="max-w-3xl mx-auto py-10 px-4">
            <div class="timeline">
              <app-events
                :filter="filter"
                :key="filter"
                v-on:account="account = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white border-l" style="width: 440px">
      <app-account v-if="account" :id="account._id" :key="account._id" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filter: 'all',
      account: null
    };
  }
};
</script>
<style>
.timeline {
  background-image: linear-gradient(
    90deg,
    transparent 21px,
    theme('colors.gray.300') 21px,
    theme('colors.gray.300') 23px,
    transparent 23px
  );
}

.hidden-scrollbar {
  overflow-y: scroll;
}

.hidden-scrollbar::-webkit-scrollbar {
  background: transparent;
}
</style>
