<template>
  <div class="px-4 py-8">
    <app-spinner v-if="loading" class="mx-auto" />
    <div v-else-if="!account" class="text-center">
      Algo deu errado,
      <span class="text-primary-600 cursor-pointer" v-on:click="load"
        >tente novamente</span
      >.
    </div>
    <div v-else class="space-y-8">
      <div class="flex items-center justify-between">
        <img
          :src="`https://ui-avatars.com/api/?background=fde68a&color=92400e&rounded=true&font-size=0.35&name=${account.owner.name}`"
          class="w-16 h-16"
        />
        <div class="flex space-x-3">
          <app-button
            white
            icon="mail"
            target="_blank"
            :to="`https://compcodes.frontapp.com/compose?mailto=mailto:${encodedEmail}`"
          >
            e-mail
          </app-button>
          <app-button
            icon="chat"
            target="_blank"
            :to="`https://wa.me/${account.phone.replace(/\+| /g, '')}`"
          >
            whatsapp
          </app-button>
        </div>
      </div>
      <app-hr />
      <div class="space-y-2">
        <div class="flex items-center">
          <app-icon
            :name="
              account.owner.type === 'individual' ? 'user' : 'office-building'
            "
            class="mr-2 text-gray-400"
          />
          <div class="flex-1"> {{ account.owner.name }} </div>
        </div>
        <div class="flex items-center">
          <app-icon name="mail" class="mr-2 text-gray-400" />
          <div class="flex-1"> {{ account.email }} </div>
        </div>
        <div class="flex items-center">
          <app-icon name="calendar" class="mr-2 text-gray-400" />
          <div class="flex-1"> {{ account.date }} </div>
        </div>
        <div class="flex items-center">
          <app-icon name="phone" class="mr-2 text-gray-400" />
          <div class="flex-1"> {{ account.phone }} </div>
        </div>
        <div class="flex items-center">
          <app-icon name="qrcode" class="mr-2 text-gray-400" />
          <div class="flex-1">
            {{
              account.qrcodeCount === 0
                ? 'Nenhum'
                : account.qrcodeCount === 1
                ? '1 QR code'
                : `${account.qrcodeCount} QR codes`
            }}
          </div>
        </div>
      </div>
      <app-hr />
      <div v-if="!subscription"> Não começou o teste gratuito </div>
      <app-timeline-item v-else white icon="refresh" color="blue">
        <div class="text-gray-900">
          <div> {{ planName }} </div>
          <div class="flex flex-wrap -m-0.5 pt-1 pb-4">
            <app-badge v-if="subscription.status === 'active'" color="green">
              Ativa
            </app-badge>
            <app-badge v-if="subscription.status === 'trialing'" color="purple">
              Teste
            </app-badge>
            <app-badge v-if="subscription.status === 'past_due'" color="red">
              Pagamento pendente
            </app-badge>
            <app-badge v-if="subscription.status === 'canceled'" color="red">
              Cancelada
            </app-badge>
            <template v-if="subscription.ok">
              <app-badge v-if="subscription.endCancel" color="red">
                Será cancelada em {{ end }}
              </app-badge>
              <app-badge v-else color="blue">
                Será renovada em {{ end }}
              </app-badge>
            </template>
          </div>
          <app-clipboard
            label="URL da última fatura"
            :value="subscription.latestInvoice.url"
          />
        </div>
      </app-timeline-item>
    </div>
  </div>
</template>
<script>
import { DateTime } from 'luxon';

export default {
  props: {
    id: String
  },
  data() {
    return {
      account: null,
      loading: false
    };
  },
  created() {
    this.load();
  },
  computed: {
    subscription() {
      return this.account?.billing.subscription;
    },
    planName() {
      const names = {
        basic: 'Plano básico',
        premium: 'Plano premium',
        vip: 'Plano VIP'
      };
      return names[this.account?.billing.subscription?.plan.name];
    },
    encodedEmail() {
      return this.account && encodeURIComponent(this.account.email);
    },
    end() {
      return (
        this.subscription &&
        DateTime.fromISO(this.subscription.end).toFormat('ff', {
          locale: 'pt-BR'
        })
      );
    }
  },
  methods: {
    load() {
      this.loading = true;
      this.api('account.retrieve.admin', { accountId: this.id })
        .then(res => {
          this.account = res.data;
          this.account.date = DateTime.fromISO(this.account.date).toFormat(
            'ff',
            { locale: 'pt-BR' }
          );
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
