<template>
  <div class="flex items-start">
    <div
      :class="[
        background,
        $attrs.onIconClick ? 'cursor-pointer' : '',
        white ? 'ring-white' : 'ring-gray-100',
        'mr-4 p-3 rounded-full ring-4'
      ]"
      v-on:click="$emit('icon-click')"
    >
      <app-icon :name="icon" :class="foreground" />
    </div>
    <div class="flex-1">
      <div> {{ title }} </div>
      <div class="text-gray-500"> {{ subtitle }} <slot /> </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    icon: String,
    color: String,
    title: String,
    subtitle: String,
    white: Boolean
  },
  computed: {
    background() {
      const backgrounds = {
        purple: 'bg-purple-200',
        yellow: 'bg-yellow-200',
        green: 'bg-green-200',
        blue: 'bg-blue-200',
        red: 'bg-red-200'
      };
      return backgrounds[this.color];
    },
    foreground() {
      const foregrounds = {
        purple: 'text-purple-800',
        yellow: 'text-yellow-800',
        green: 'text-green-800',
        blue: 'text-blue-800',
        red: 'text-red-800'
      };
      return foregrounds[this.color];
    }
  }
};
</script>
