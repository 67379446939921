<template>
  <div class="space-y-10">
    <app-timeline-item
      v-for="event in events"
      :key="event._id"
      :icon="event.icon"
      :color="event.color"
      :title="event.title"
      :subtitle="event.dateRelative"
      v-on:icon-click="$emit('account', event.account)"
    >
      <div v-if="event.task" class="text-gray-900">
        <app-checkbox
          :label="event.task.description"
          :value="event.task.completed"
          v-on:change="updateTask(event, $event)"
        />
      </div>
    </app-timeline-item>
    <app-timeline-item
      v-if="loading"
      icon="refresh"
      color="blue"
      title="Carregando"
      subtitle="Por favor, aguarde..."
    />
    <app-timeline-item
      v-else-if="error"
      icon="x"
      color="red"
      title="Ocorreu um erro"
    >
      Por favor,
      <span v-on:click="load" class="text-primary-600 cursor-pointer">
        tente novamente
      </span>
    </app-timeline-item>
    <app-timeline-item
      v-else-if="!end"
      icon="plus"
      color="blue"
      v-on:icon-click="load"
    />
    <app-timeline-item
      v-else-if="!events.length"
      icon="check"
      color="green"
      title="Nenhum evento pendente"
      subtitle="Aproveite!"
    />
  </div>
</template>
<script>
import { DateTime } from 'luxon';

export default {
  props: {
    filter: String
  },
  data() {
    return {
      events: [],
      end: false,
      error: false,
      loading: false,
      reloadTimeoutId: null,
      recalcIntervalId: null
    };
  },
  created() {
    this.load();
    this.recalcIntervalId = setInterval(this.calculateEvents, 500);
  },
  beforeUnmount() {
    if (this.reloadTimeoutId) {
      clearTimeout(this.reloadTimeoutId);
    }
    clearInterval(this.recalcIntervalId);
  },
  methods: {
    load() {
      this.error = false;
      this.loading = true;
      this.api('event.list', {
        pending: this.filter === 'pending',
        start: this.events.map(({ date, _id }) => ({ date, _id })).at(-1),
        limit: 50
      })
        .then(res => {
          this.events.push(...res.data);
          this.end = res.data.length < 50;
          this.calculateEvents();
          if (!this.reloadTimeoutId) this.update();
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.reloadTimeoutId = setTimeout(() => {
        this.api('event.list', {
          pending: this.filter === 'pending',
          end: this.events.map(({ date, _id }) => ({ date, _id })).at(0)
        })
          .then(res => {
            this.events.unshift(...res.data);
            this.calculateEvents();
            if (res.data.length) {
              new Notification('Compcodes', { requireInteraction: true });
              this.$root.notificationAudio.play();
            }
          })
          .catch(() => {})
          .finally(() => {
            this.update();
          });
      }, 10000);
    },
    calculateEvents() {
      this.events.forEach(event => {
        const type = event.type;
        const date = DateTime.fromISO(event.date);
        const dateRelative = date.toRelative({ locale: 'pt-BR' });

        const icons = {
          'account.created': 'user',
          'checkout.created': 'credit-card',
          'subscription.updated': 'refresh'
        };

        const colors = {
          'account.created': 'yellow',
          'checkout.created': 'purple',
          'subscription.updated': 'blue'
        };

        const titles = {
          'account.created': () => event.account.owner.name,
          'checkout.created': () => event.account.owner.name,
          'subscription.updated': () => event.account.owner.name
        };

        event.icon = icons[type];
        event.color = colors[type];
        event.title = titles[type]();
        event.dateRelative = dateRelative;
      });
    },
    updateTask(event, completed) {
      if (completed && this.filter === 'pending') {
        this.events = this.events.filter(e => e !== event);
      }
      this.api('event.update', { eventId: event._id, completed }).catch(
        () => {}
      );
    }
  }
};
</script>
