<template>
  <template v-if="session">
    <!-- todo -->
    <div v-if="session.exiting"> exiting </div>
    <div v-else-if="session.loading"> loading </div>
    <div v-else-if="!session.account"> error </div>

    <div v-else class="flex">
      <div class="bg-gray-800 text-gray-300 flex flex-col">
        <router-link
          v-for="link in links"
          :key="link.icon"
          :to="{ name: link.route }"
          :class="[
            link.route === $route.name
              ? 'border-blue-500'
              : 'border-transparent',
            'hover:bg-gray-700 hover:text-gray-200 border-l-4 py-5 pr-5 pl-4'
          ]"
        >
          <app-icon :name="link.icon" />
        </router-link>
        <div
          v-on:click="$root.exit"
          class="
            hover:bg-gray-700 hover:text-gray-200
            cursor-pointer
            mt-auto
            p-5
          "
        >
          <app-icon name="logout" />
        </div>
      </div>
      <div class="flex-1 grid">
        <router-view />
      </div>
    </div>
  </template>
</template>
<script>
export default {
  data() {
    return {
      links: [
        {
          icon: 'home',
          route: 'home'
        },
        {
          icon: 'users',
          route: 'accounts'
        },
        {
          icon: 'rss',
          route: 'events'
        }
      ]
    };
  },
  computed: {
    session() {
      return this.$root.session;
    }
  },
  watch: {
    '$root.session': {
      immediate: true,
      handler(session, oldValue) {
        if (!session) {
          if (oldValue !== undefined) this.$router.push({ name: 'log-in' });
          else this.$router.replace({ name: 'log-in' });
        }
      }
    }
  }
};
</script>
