<template>
  <div
    :class="[
      background,
      foreground,
      'py-0.5 px-2 m-0.5 rounded-md text-sm font-medium'
    ]"
  >
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    color: String
  },
  computed: {
    background() {
      const backgrounds = {
        purple: 'bg-purple-200',
        yellow: 'bg-yellow-200',
        green: 'bg-green-200',
        blue: 'bg-blue-200',
        red: 'bg-red-200'
      };
      return backgrounds[this.color];
    },
    foreground() {
      const foregrounds = {
        purple: 'text-purple-800',
        yellow: 'text-yellow-800',
        green: 'text-green-800',
        blue: 'text-blue-800',
        red: 'text-red-800'
      };
      return foregrounds[this.color];
    }
  }
};
</script>
