<template>
  <div class="h-screen bg-gray-100 text-gray-900 grid">
    <router-view />
    <app-notifications />
  </div>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      session: null,
      notificationAudio: new Audio('/notification.mp3'),
      notifications: []
    };
  },
  created() {
    axios.interceptors.response.use(
      res => res,
      err => {
        if (axios.isCancel(err)) {
          return new Promise(() => {});
        }
        if (err.response && err.response.status === 401) {
          if (this.session && this.session._id === err.response.data.session) {
            this.updateSession(null);
          }
          return new Promise(() => {});
        }
        return Promise.reject(err);
      }
    );
    window.onstorage = () => {
      this.updateSession();
    };
    this.updateSession();
  },
  mounted() {
    if (Notification.permission === 'default') {
      Notification.requestPermission();
    }
  },
  watch: {
    session() {
      const bearer = this.session && 'Bearer ' + this.session.token;
      axios.defaults.headers.common.authorization = bearer;
      if (this.session) this.retrieveAccount();
    }
  },
  methods: {
    ls(key, value) {
      if (value === undefined) return JSON.parse(localStorage.getItem(key));
      if (value === null) localStorage.removeItem(key);
      else localStorage.setItem(key, JSON.stringify(value));
    },
    updateSession(value) {
      if (value !== undefined) {
        this.ls('session', value);
      }
      const session = this.ls('session');
      if (!session) {
        this.session = null;
      } else if (!this.session || this.session._id !== session._id) {
        this.session = {
          ...session,
          account: null,
          loading: false,
          exiting: false
        };
      }
    },
    retrieveAccount() {
      const session = this.session;
      session.loading = true;
      this.api('account.retrieve')
        .catch(() => ({ data: null }))
        .then(({ data }) => {
          session.account = data;
          session.loading = false;
        });
    },
    exit() {
      const session = this.session;
      session.exiting = true;
      this.api('session.delete', { sessionId: session._id })
        .then(() => {
          if (this.session === session) {
            this.updateSession(null);
          }
        })
        .catch(() => {
          if (this.session === session) {
            this.notifications.push({
              message: 'Algo deu errado, tente novamente.'
            });
          }
        })
        .finally(() => {
          session.exiting = false;
        });
    },
    showErrors(...inputs) {
      const inputsWithError = inputs.filter(input => input.error);
      inputsWithError.forEach(input => {
        input.dirty = true;
        if (input.el === document.activeElement) input.el.blur();
      });
      return !!inputsWithError.length;
    }
  }
};
</script>
