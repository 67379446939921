<template>
  <div>
    <div class="flex">
      <label :for="id" class="text-sm font-medium text-gray-700 cursor-pointer">
        {{ label }}
      </label>
    </div>
    <div class="mt-1 flex rounded-md shadow-sm">
      <div class="flex-grow focus-within:z-10">
        <input
          :id="id"
          readonly
          type="text"
          :value="value"
          class="
            border-gray-300
            focus:outline-none focus:ring-primary-600 focus:border-primary-600
            block
            w-full
            text-sm
            rounded-none rounded-l-md
          "
        />
      </div>
      <button
        ref="button"
        type="button"
        :data-clipboard-target="`#${id}`"
        class="
          -ml-px
          px-3
          py-2
          border border-gray-300
          rounded-r-md
          text-gray-700
          bg-gray-50
          hover:bg-gray-100
          focus:outline-none
          focus:ring-1
          focus:ring-primary-600
          focus:border-primary-600
        "
      >
        <app-icon name="clipboard-copy" />
      </button>
    </div>
  </div>
</template>
<script>
import ClipboardJS from 'clipboard';

export default {
  props: {
    label: String,
    value: String
  },
  data() {
    return {
      id: 'id' + Math.random().toFixed(10).slice(2)
    };
  },
  mounted() {
    const clipboard = new ClipboardJS(this.$refs.button);
    clipboard.on('success', e => {
      e.clearSelection();
    });
  }
};
</script>
